import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Button } from '@components/ui/button';

export default function Examples() {
  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();

  useEffect(() => {
    controls1.start({
      x: ['-100%', '0%'],
      transition: { duration: 80, repeat: Infinity, ease: 'linear' }
    });
    controls2.start({
      x: ['-100%', '0%'],
      transition: { duration: 40, repeat: Infinity, ease: 'linear' }
    });
    controls3.start({
      x: ['-100%', '0%'],
      transition: { duration: 60, repeat: Infinity, ease: 'linear' }
    });
  }, [controls1, controls2, controls3]);

  return (
    <div className="w-full">
      <h2 className="text-xs font-medium tracking-wider leading-7 mb-2 uppercase text-muted-foreground">Examples</h2>
      <p className="text-3xl font-semibold tracking-tight text-primary mb-1">Skeptical? Take a look.</p>
      <p className="text-lg font-normal leading-8 mb-6">Here are some examples of what PicStudio.AI can do.</p>
      <Button size={'sm'} className="mb-6" variant={'accent'} asChild>
        <Link href="/examples-and-reviews" className="">
          View examples of reviews
        </Link>
      </Button>
      <div>
        <div className="mx-auto max-w-7xl overflow-hidden rounded-xl items-center flex flex-col">
          <div className="overflow-hidden relative">
            <motion.div className="flex flex-row" animate={controls1}>
              <Image src="/images/examples/row1.webp" alt="Example images" width={1920} height={1080} />
              <Image src="/images/examples/row1.webp" alt="Example images" width={1920} height={1080} />
            </motion.div>
          </div>
          <div className="overflow-hidden relative">
            <motion.div className="flex flex-row" animate={controls2}>
              <Image src="/images/examples/row3.webp" alt="Example images" width={1920} height={1080} className="" />
              <Image src="/images/examples/row3.webp" alt="Example images" width={1920} height={1080} />
            </motion.div>
          </div>
          <div className="overflow-hidden w-full relative">
            <motion.div className="flex flex-row" animate={controls3}>
              <Image src="/images/examples/row2.webp" alt="Example images" width={1920} height={1080} />
              <Image src="/images/examples/row2.webp" alt="Example images" width={1920} height={1080} />
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}
