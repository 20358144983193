import { Button } from '@components/ui/button';
import { DEFAULT_PHOTO_ALBUM_PHOTO_COUNT } from '@lib/global';
import Image from 'next/image';
import Link from 'next/link';

export default function OpenPacks() {
  return (
    <div className="w-full">
      <div className="max-w-md">
        <h2 className="text-xs font-medium tracking-wider leading-7 mb-2 uppercase text-muted-foreground">
          Photo Packs
        </h2>
        <p className="text-3xl font-semibold tracking-tight text-primary mb-1">Open your first pack!</p>
        <p className="text-lg font-normal leading-8 mb-4">
          Photos are grouped into packs of {DEFAULT_PHOTO_ALBUM_PHOTO_COUNT}.
        </p>
      </div>

      <Link href="/packs/browse">
        <Button size={'sm'} className="mb-6" variant={'accent'}>
          Browse Packs
        </Button>
      </Link>
      <div className="bg-gradient-to-br from-gray-50 to-slate-300 dark:from-neutral-800 dark:to-background aspect-[4/3] flex items-center rounded-2xl overflow-clip">
        <Image
          src="/images/pack-covers/open-pack.webp"
          alt="Product screenshot"
          className="w-[100%] max-w-none"
          width={1216}
          height={721}
        />
      </div>
    </div>
  );
}
