import { Button } from '@components/ui/button';
import { Instagram } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';

export default function InstagramConnect() {
  return (
    <div className="w-full">
      <div className="max-w-md">
        <h2 className="text-xs font-medium tracking-wider leading-7 mb-2 uppercase text-muted-foreground">
          Instagram Connect
        </h2>
        <p className="text-3xl font-semibold tracking-tight text-primary mb-1">Uploading is easy.</p>
        <p className="text-lg font-normal leading-8 mb-4">
          Connect your Instagram, and our face-matching AI will find the best photos of you.
        </p>
      </div>

      <Button
        size={'sm'}
        // onClick={redirectToIG}
        className="hover:opacity-90 bg-gradient-to-r from-amber-500 via-rose-500 to-fuchsia-600 text-white mb-10"
        asChild
      >
        <Link href="/login">
          <Instagram strokeWidth={2.5} className="w-4 h-4 mr-2 text-accent-foreground" />
          Import from Instagram
        </Link>
      </Button>

      <div className="bg-gradient-to-t from-studio-500 to-studio-200 dark:bg-none rounded-2xl overflow-clip">
        <Image
          src="/images/ig-browser.webp"
          alt="Product screenshot"
          className="w-[100%] max-w-none rounded-xl md:-ml-4 lg:-ml-0 -mb-10 opacity-90"
          width={1216}
          height={721}
        />
      </div>
      {/* <div className="w-32 h-32 bg-gradient-to-r from-amber-500 via-rose-500 to-fuchsia-600 text-white rounded-3xl justify-center items-center flex">
        <Instagram strokeWidth={2} className="w-3/4 h-3/4 text-accent-foreground" />
      </div> */}
    </div>
  );
}
