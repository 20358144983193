import { Button } from '@components/ui/button';
import { PRICE_ID_REVENUE, STARTER_PRICE_ID } from '@/_data/constants';
import Link from 'next/link';
import React from 'react';
import { ALBUM_GENERATION_TIME_MINUTES, DEFAULT_PHOTO_ALBUM_PHOTO_COUNT } from '@lib/global';

export default function QuickPrice() {
  return (
    <div className="bg-gradient-to-tr bg-accent p-6 rounded-xl">
      <div className="mx-auto max-w-2xl text-left md:text-center">
        <h2 className="text-2xl font-bold tracking-tight text-accent-foreground mb-2">
          {DEFAULT_PHOTO_ALBUM_PHOTO_COUNT} photos for just ${PRICE_ID_REVENUE[STARTER_PRICE_ID]}
        </h2>
        <p className="text-accent-foreground">Get high quality portraits in {ALBUM_GENERATION_TIME_MINUTES} minutes.</p>

        <Button className="text-accent-foreground px-0 pb-0" variant={'link'} asChild>
          <Link href="/#pricing">
            Learn more
            <span aria-hidden="true" className="ml-2">
              &rarr;
            </span>
          </Link>
        </Button>
      </div>
    </div>
  );
}
