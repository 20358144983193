import React from 'react';
import Image from 'next/image';
import QuickPrice from './QuickPrice';
import { PiArrowBendLeftDownFill, PiArrowBendRightDownFill } from 'react-icons/pi';

export default function Hero4() {
  return (
    // <div className="left-1/2 -translate-x-1/2 relative  overflow-hidden"
    <div className="w-full isolate">
      <div className="relative isolate">
        <div className="text-left md:text-center my-10 mt-20 lg:mb-10 px-6 lg:max-w-2xl flex flex-col m-auto">
          <h2 className="w-full text-4xl lg:text-6xl flex items-center justify-start md:justify-center m-auto text-primary mb-6">
            Photos in minutes
          </h2>
          <p className="text-xl max-w-2xl flex items-center justify-center m-auto leading-8 text-primary">
            {' '}
            Upload selfies, choose a photo pack & receive 120+ stunning portraits. Perfect for social media.
          </p>
        </div>
        <div className="">
          <div className="bg-card p-6 flex flex-col gap-4 rounded-xl lg:rounded-2xl overflow-hidden">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="w-full">
                <div className="flex flex-row bg-red-500/0 gap-1 mb-1 justify-center">
                  <PiArrowBendLeftDownFill size={24} className="text-primary relative top-5" />
                  <Image src="/images/marketing/nate1.webp" width={48} height={48} alt={''} className="w-12 h-12" />
                  <Image src="/images/marketing/nate2.webp" width={48} height={48} alt={''} className="w-12 h-12" />
                  <Image src="/images/marketing/nate3.webp" width={48} height={48} alt={''} className="w-12 h-12" />
                  <Image src="/images/marketing/nate4.webp" width={48} height={48} alt={''} className="w-12 h-12" />
                  <PiArrowBendRightDownFill size={24} className="text-primary relative top-5" />
                </div>
                <div className="grid grid-cols-2 gap-0">
                  <Image
                    src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/b04a29b9-04dc-474f-fcaf-e90558bb5d00/public"
                    width={1024}
                    height={1024}
                    alt={''}
                    className="w-full aspect-square"
                  />
                  <Image
                    src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/cd2ee82d-5fe3-4624-716b-97ece58afd00/public"
                    width={1024}
                    height={1024}
                    alt={''}
                    className="w-full aspect-square"
                  />
                  <Image
                    src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/9ed3717f-b514-4597-7ca8-703573229e00/public"
                    width={1024}
                    height={1024}
                    alt={''}
                    className="w-full aspect-square"
                  />
                  <Image
                    src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/9ad7a27b-6188-4e48-3b8f-bdf83c7b4900/public"
                    width={1024}
                    height={1024}
                    alt={''}
                    className="w-full aspect-square"
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="flex flex-row bg-red-500/0 gap-1 mb-1 justify-center">
                  <PiArrowBendLeftDownFill size={24} className="text-primary relative top-5" />
                  <Image src="/images/marketing/klee1.webp" width={48} height={48} alt={''} className="w-12 h-12" />
                  <Image src="/images/marketing/klee2.webp" width={48} height={48} alt={''} className="w-12 h-12" />
                  <Image src="/images/marketing/klee3.webp" width={48} height={48} alt={''} className="w-12 h-12" />
                  <Image src="/images/marketing/klee4.webp" width={48} height={48} alt={''} className="w-12 h-12" />
                  <PiArrowBendRightDownFill size={24} className="text-primary relative top-5" />
                </div>
                <div className="grid grid-cols-2">
                  <Image
                    src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/313245f1-c3b4-4210-586d-048c33e20c00/public"
                    width={1024}
                    height={1024}
                    alt={''}
                    className="w-full aspect-square"
                  />
                  <Image
                    src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/9f8234b4-da1c-4c63-9b38-baf6d8ed4d00/public"
                    width={1024}
                    height={1024}
                    alt={''}
                    className="w-full aspect-square"
                  />
                  <Image
                    src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/461c9531-e512-4bc1-9bd5-e06d3ee9b700/public"
                    width={1024}
                    height={1024}
                    alt={''}
                    className="w-full aspect-square"
                  />
                  <Image
                    src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/edadb3a0-4b6d-4748-54f0-4566f3ed8f00/public"
                    width={1024}
                    height={1024}
                    alt={''}
                    className="w-full aspect-square"
                  />
                </div>
              </div>
            </div>
            <QuickPrice />
          </div>
        </div>
      </div>
    </div>
  );
}
