import React from 'react';
import Link from 'next/link';
import { Button } from '@components/ui/button';
import Image from 'next/image';

export default function Packs() {
  return (
    <div className="relative bg-purple-500/0 justify-center flex flex-col items-center">
      <div className="">
        <div className="mx-auto max-w-2xl text-center items-center justify-center flex flex-col mb-10">
          <h2 className="text-4xl font-bold tracking-tight text-studio-900 sm:text-6xl">Pick a photo pack</h2>
          <p className="mt-6 text-lg leading-8 text-studio-600 max-w-lg w-full mb-10">
            Get photos in a variety of styles and themes. Choose from our selection of packs to get started.
          </p>
          <div className="flex items-center justify-center gap-x-6">
            <Button className="" variant={'accent'} size={'xl'} asChild>
              <Link href="/packs/browse">Browse packs</Link>
            </Button>
          </div>
        </div>
      </div>
      <div className="px-6 lg:px-8 md:-mx-32 lg:-mx-48 xl:-mx-64 overflow-x-auto w-full">
        <div className="grid grid-cols-3 sm:grid-cols-5 gap-1 md:gap-4 bg-red-500/0 pb-16">
          {/* First pack */}
          <div className="pt-0 hidden sm:block pb-8">
            <Image
              alt={'Pack'}
              src={'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/f369fb4d-80bd-4864-5d5e-19abe6c21100/public'}
              width={1000}
              height={1000}
              className="col-span-1 rounded-3xl shadow-[rgba(0,_0,_0,_0.3)_0px_16px_16px_-7px]"
            />
          </div>
          {/* Second pack */}
          <div className="relative pt-[30%]">
            <Image
              alt={'Pack'}
              src={'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/4c2d86f2-8cb9-4a42-bce0-4763d2c54300/public'}
              width={1000}
              height={1000}
              className="col-span-1 rounded-3xl shadow-[rgba(0,_0,_0,_0.3)_0px_16px_16px_-7px]"
            />
          </div>
          {/* Middle pack */}
          <div className="pt-[15%]">
            <Image
              alt={'Pack'}
              src={'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/3e285875-0bcd-47fb-4a88-86722bb3b400/public'}
              width={1000}
              height={1000}
              className="col-span-1 rounded-3xl shadow-[rgba(0,_0,_0,_0.3)_0px_16px_16px_-7px]"
            />
          </div>
          {/* Fourth pack */}
          <div className="pt-[30%]">
            <Image
              alt={'Pack'}
              src={'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/5f5e8ad2-99a1-4c80-33a7-b108dabd0100/public'}
              width={1000}
              height={1000}
              className="col-span-1 rounded-3xl shadow-[rgba(0,_0,_0,_0.3)_0px_16px_16px_-7px]"
            />
          </div>
          {/* Last pack */}
          <div className="pt-0 hidden sm:block">
            <Image
              alt={'Pack'}
              src={'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/5d13f864-567c-463b-5716-fd4a8082ac00/public'}
              width={1000}
              height={1000}
              className="col-span-1 rounded-3xl shadow-[rgba(0,_0,_0,_0.3)_0px_16px_16px_-7px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
